var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-upload"},[_c('el-upload',{staticClass:"img-upload",attrs:{"action":"/centre/util/upload","headers":{
      'X-Token': `Bearer ${_vm.token}`
    },"data":{
      type: 1,
      dir_prefix: 'dir_prefix',
      save: 1
    },"show-file-list":false,"before-upload":_vm.beforeAvatarUpload,"on-exceed":_vm.handleExceed,"on-success":_vm.handleAvatarSuccess,"on-error":_vm.handleAvatarError}},[(_vm.imageUrl)?_c('img',{staticClass:"upload-image",attrs:{"src":_vm.imageUrl,"alt":""}}):(!_vm.imageUrl)?_c('i',{staticClass:"avatar-uploader-icon",class:_vm.uploadLoading === '1' ? 'el-icon-loading' : 'el-icon-plus'}):_vm._e()]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }