<template>
  <div class="img-upload">
    <el-upload
      class="img-upload"
      action="/centre/util/upload"
      :headers="{
        'X-Token': `Bearer ${token}`
      }"
      :data="{
        type: 1,
        dir_prefix: 'dir_prefix',
        save: 1
      }"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :on-exceed="handleExceed"
      :on-success="handleAvatarSuccess"
      :on-error="handleAvatarError"
    >
      <img v-if="imageUrl" :src="imageUrl" class="upload-image" alt />
      <i
        v-else-if="!imageUrl"
        :class="uploadLoading === '1' ? 'el-icon-loading' : 'el-icon-plus'"
        class="avatar-uploader-icon"
      ></i>
      <!-- <i class="el-icon-search enlarge-box" @click.stop="handlePictureCardPreview" v-show="imageUrl"></i> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      uploadLoading: '2',
      dialogImageUrl: '',
      dialogVisible: false,
      token: this.$store.state.token,
      imageUrl: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.imageUrl = newValue
    },
  },
  mounted() {
  },
  methods: {
    beforeAvatarUpload(file) {
      this.imageUrl = ''
      this.uploadLoading = '1'
      const isJPG = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
        this.uploadLoading = '2'
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
        this.uploadLoading = '2'
      }
      return isJPG && isLt2M
    },

    handleExceed() {
      // this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      this.$message.warning('最多上传一张')
    },
    handleAvatarSuccess(res, file) {
      if (res.status === 200) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.uploadLoading = '2'
        this.$emit('input', res.data.fileName)
      } else {
        this.uploadLoading = '2'
        this.$message.error(res.message)
      }
    },
    handleAvatarError(err) {
      this.uploadLoading = '2'
      console.log('1234566566556');
      this.$message.error(JSON.parse(err.message).message)
    },
    handlePictureCardPreview() {
      this.dialogImageUrl = this.imageUrl;
      this.dialogVisible = true;
    }
  }
}
</script>

<style>
.img-upload .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 86px;
}
</style>

<style scoped>
.img-upload .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 86px;
  height: 86px;
  line-height: 86px;
  text-align: center;
}

.upload-image {
  object-fit: cover;
  width: 86px;
  height: 86px;
  display: block;
}
.enlarge-box {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  color: #ccc;
  bottom: 70px;
}
</style>
