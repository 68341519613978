<template>
  <div class="enterprise-stores">
    <div class="stores-bg">
      <div class="step-box">
        <el-steps :active="active" finish-status="success" align-center>
          <el-step :title="$t('stores.merAuthInfo')"></el-step>
          <el-step :title="$t('stores.setStoreInfo')"></el-step>
          <el-step :title="$t('stores.success')"></el-step>
        </el-steps>
      </div>
      <div class="enterprise-stores-child">
        <div class="reason-form">
          <div
            class="d-flex flex-direction-column align-items-center color-red"
            v-if="this.$route.query.newStatus == 3"
          >
            <span class="d-flex align-items-center">
              <i class="el-icon-warning"></i>
              {{ declinedReason }}
            </span>
          </div>
          <div class="form-box" v-if="active === 0">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="auto"
              class="demo-ruleForm"
            >
              <h3 style="padding:20px 0;">{{ $t('stores.realID') }}</h3>
              <el-form-item :label="$t('stores.certificateType')" prop="idCardType">
                <el-select v-model="ruleForm.idCardType" :placeholder="$t('base.pleaseSelectA')">
                  <el-option
                    v-for="item in documentType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('stores.documentName')" prop="idCardName">
                <el-input v-model="ruleForm.idCardName" maxlength="125" show-word-limit></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.documentNumber')" prop="idCardNumber">
                <el-input v-model="ruleForm.idCardNumber" maxlength="64" show-word-limit></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.idPhoto')" prop="frontIdCard">
                <uploadImg v-if="ruleForm.frontIdCard" v-model="ruleForm.frontIdCard" />
                <div v-else>
                  <uploadImg v-model="ruleForm.frontIdCard" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.idPhotoBack')" prop="reverseIdCard">
                <uploadImg v-if="ruleForm.reverseIdCard" v-model="ruleForm.reverseIdCard" />
                <div v-else>
                  <uploadImg v-model="ruleForm.reverseIdCard" />
                </div>
              </el-form-item>
              <h3 style="padding:20px 0;">{{ $t('stores.bankInfo') }}</h3>
              <el-form-item :label="$t('stores.bankCardPhoto')" prop="bankPic">
                <uploadImg v-if="ruleForm.bankPic" v-model="ruleForm.bankPic" />
                <div v-else>
                  <uploadImg v-model="ruleForm.bankPic" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.accountName')" prop="bankAccount">
                <el-input
                  v-model="ruleForm.bankAccount"
                  maxlength="64"
                  show-word-limit
                  :placeholder="$t('stores.accountName')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.accountNumber')" prop="bankNumber">
                <el-input
                  v-model="ruleForm.bankNumber"
                  maxlength="64"
                  show-word-limit
                  :placeholder="$t('stores.accountNumber')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.bank')" prop="bankId">
                <el-select
                  v-model="ruleForm.bankId"
                  :placeholder="$t('stores.bank')"
                  @change="changeBank"
                  clearable
                >
                  <el-option
                    v-for="item in bankArr"
                    :key="Number(item.id)"
                    :label="item.bankName"
                    :value="Number(item.id)"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <Affix :offset-bottom="0">
              <div class="button-box d-flex justify-space-between">
                <el-button @click="cancelBtn()">{{$t('base.cancel')}}</el-button>
                <div>
                  <el-button @click="keepDraft()">{{$t('stores.saveDraft')}}</el-button>
                  <el-button type="primary" @click="nextSubmitForm('ruleForm')">{{$t('stores.nextStepSettingInfo')}}</el-button>
                </div>
              </div>
            </Affix>
          </div>
          <div class="form-box" v-else-if="active === 1">
            <el-form
              :model="storeForm"
              :rules="rulesNext"
              ref="storeForm"
              label-width="180px"
              class="demo-ruleForm"
            >
              <el-form-item :label="$t('stores.merName')" prop="merName">
                <el-input v-model="storeForm.merName" maxlength="64" show-word-limit></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.merClass')" prop="categoryId">
                <el-select v-model="storeForm.categoryId" :label="$t('store.pleaseMerClass')">
                  <el-option
                    v-for="item in businessClass"
                    :key="item.categoryId"
                    :label="item.cateName"
                    :value="item.categoryId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('stores.merLogo')" prop="logo">
                <uploadImg v-if="storeForm.logo" v-model="storeForm.logo" />
                <div v-else>
                  <uploadImg v-model="storeForm.logo" />
                </div>
              </el-form-item>
              <el-form-item :label="$t('stores.merInfo')" prop="merInfo">
                <el-input
                  type="textarea"
                  :rows="4"
                  v-model="storeForm.merInfo"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('stores.merAddress')" prop="merAddress">
                <el-input v-model="storeForm.merAddress" maxlength="255" show-word-limit></el-input>
              </el-form-item>
            </el-form>
            <div class="button-box d-flex justify-space-between">
              <el-button @click="cancelBtn()">{{$t('base.cancel')}}</el-button>
              <div>
                <el-button @click="keepStoreDraft()">{{$t('stores.saveDraft')}}</el-button>
                <el-button @click="previousStep()">{{$t('base.previousStep')}}</el-button>
                <el-button type="primary" @click="submitForm('storeForm')">{{$t('base.submit')}}</el-button>
              </div>
            </div>
          </div>
          <div
            class="form-box d-flex flex-direction-column align-items-center"
            v-else-if="active === 3"
          >
            <div class="d-flex flex-direction-column align-items-center">
              <span class="pad-20-0 fs-20 font-weight-bold">{{$t('stores.successSubmit')}}</span>
              <img
                class="success-logo pad-50-0"
                width="60"
                height="60"
                src="@/assets/image/home/ic_gong.png"
                alt
              />
            </div>
            <div class="d-flex flex-direction-column align-items-center pad-20-0">
              <p class="pad-top-20">{{$t('stores.submitTips')}}</p>
            </div>
            <div
              class="d-flex flex-direction-column justify-content-center align-items-center pad-top-50 w-100 mar-bottom-10 login-btn"
            >
              <div class="mat-20 w-100 d-flex justify-content-center">
                <el-button
                  type="primary"
                  style="width:100%;margin:50px 0;"
                  @click="viewRecords()"
                >{{$t('settled.viewAdmissionRecord')}}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uploadImg from "@/components/upImage/uploadImg";
import Affix from '@/components/affix/index.vue'
import { bankArr, applySettlement, businessClass, storeInformation } from '@/api/base'
export default {
  components: {
    uploadImg,
    Affix,
  },
  data() {
    return {
      active: 0,
      documentType: [{
        value: 1,
        label: this.$t('stores.idCard')
      }, {
        value: 2,
        label: this.$t('stores.passport')
      }, {
        value: 3,
        label: this.$t('stores.driveLicense')
      },],
      merId: this.$route.query.merId || '', // 商户详情表的id
      id: this.$route.query.id || '',
      declinedReason: this.$route.query.declinedReason || '',// 拒绝理由
      // 认证信息
      ruleForm: {
        merType: 1,//1个人2企业
        deliveryPlace: 1,//1本地3跨境
        idCardType: null,// 证件类型
        idCardName: '',// 证件姓名
        idCardNumber: '',//证件号码
        frontIdCard: '',// 证件照片（正面）
        reverseIdCard: '',// 证件照片（反面）
        bankPic: '', // 银行卡照片
        bankAccount: '', // 银行卡绑定的户主名
        bankNumber: '', // 银行卡账号
        bankId: null, //银行ID
        bankName: null, //银行名称
      },
      bankArr: [],// 银行列表
      rules: {
        idCardType: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.certificateType'), trigger: 'blur' }
        ],
        idCardName: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.documentName'), trigger: 'blur' }
        ],
        idCardNumber: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.documentNumber'), trigger: 'blur' }
        ],
        frontIdCard: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.idPhoto'), trigger: "change" }
        ],
        reverseIdCard: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.idPhotoBack'), trigger: "change" }
        ],
        bankPic: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.bankCardPhoto'), trigger: "change" }
        ],
        bankAccount: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.accountName'), trigger: 'blur' }
        ],
        bankNumber: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.accountNumber'), trigger: 'blur' }
        ],
        bankId: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.bank'), trigger: 'blur' }
        ],
      },
      // 店铺信息
      storeForm: {
        site: this.$route.query.area == '63' ? 1 : 2,
        merName: '',
        categoryId: null,
        logo: 'https://monmall-1253436512.cos.accelerate.myqcloud.com/merchant/2022-03/19a55aa303434978b48788c0aa472005.jpg',
        merInfo: '',
        merAddress: '',
      },
      // 经营分类数据
      businessClass: [],
      rulesNext: {
        merName: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merName'), trigger: 'blur' }
        ],
        categoryId: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merClass'), trigger: 'blur' }
        ],
        logo: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merLogo'), trigger: 'blur' }
        ],
        merInfo: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merInfo'), trigger: 'blur' }
        ],
        merAddress: [
          { required: true, message: this.$t('base.pleaseInput') + this.$t('stores.merAddress'), trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.getBankArr()
    this.merId ? this.getStoreInformation() : ''
  },
  methods: {
    // 获取银行列表
    getBankArr() {
      bankArr().then(res => {
        this.bankArr = res.data
      })
    },
    // 根据选中的银行id获取银行名称
    changeBank(val) {
      let resultArr = this.bankArr.filter((item) => {
        return Number(item.id) === val
      });
      this.ruleForm.bankName = resultArr[0].bankName
    },
    // 经营类别
    getBusinessClassArr() {
      businessClass().then(res => {
        this.businessClass = res.data
      })
    },
    // 获取店铺信息
    getStoreInformation() {
      storeInformation(this.merId).then(res => {
        this.ruleForm = res.data
        this.storeForm = res.data
        this.storeForm.site = this.$route.query.site || 2
        // this.declinedReason = res.data.declinedReason
      })
    },
    // 下一步
    nextSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.active = 1
          this.getBusinessClassArr()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 认证信息保存草稿
    keepDraft() {
      const data = Object.assign(this.ruleForm, this.storeForm)
      applySettlement(false, this.id, data).then(res => {
        console.log(res);
        this.$router.push({ path: '/signIn/settled/applicationRecord' })
      })
    },
    // 店铺信息保存草稿
    keepStoreDraft() {
      console.log(this.storeForm.site, '123456');
      const data = Object.assign(this.ruleForm, this.storeForm)
      applySettlement(false, this.id, data).then(res => {
        console.log(res);
        this.$router.push({ path: '/signIn/settled/applicationRecord' })
      })
    },
    // 上一步
    previousStep() {
      this.active = 0
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = Object.assign(this.ruleForm, this.storeForm)
          applySettlement(true, this.id, data).then(res => {
            this.$message.success(res.message)
            this.active = 3
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelBtn() {
      this.$router.push({ path: '/signIn/settled/applicationRecord' })
    },
    // 查看入驻记录
    viewRecords() {
      this.$router.push({ path: '/signIn/settled/applicationRecord' })
    }
  }
}
</script>
<style scoped>
.enterprise-stores {
  background: #f7f9ff;
  padding: 60px 0 40px;
  box-sizing: border-box;
}
.stores-bg {
  background: url("../../../assets/image/login/bg_img_apply.png") no-repeat;
  background-size: 100% 180px;
}
.enterprise-stores-child {
  padding: 0 20%;
  background-color: #f7f9ff;
}
.reason-form {
  padding-top: 50px;
  min-width: 375px;
  background: #fff;
  position: relative;
  top: -20px;
  border-radius: 4px;
}
.demo-ruleForm {
  padding: 10px 20%;
}
.button-box {
  background: #fff;
  padding: 10px;
  border-top: 1px solid #ccc;
}
</style>
